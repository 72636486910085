<template>
  <div class="tour">
    <!-- Step 1 modal -->
    <b-modal
      content-class="steps"
      ref="tour"
      id="tour"
      no-close-on-backdrop
      centered
    >
      <div v-if="step == 1">
        <div class="title">How to create your first campaign!</div>
        <p class="subTitle">Step 1 (Creare „New Campaign)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-1.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="button-row">
          <a
            @click="step = 2"
            href="javascript:void(0)"
            class="btn btn-primary full"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 2">
        <div class="title">How to create your first campaign!</div>
        <p class="subTitle">Step 2 (Set the target and buget)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-2.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="inline-buttons">
          <a @click="step = 1" href="javascript:void(0)" class="btn btn-default"
            >Back</a
          >
          <a @click="step = 3" href="javascript:void(0)" class="btn btn-primary"
            >Next</a
          >
        </div>
      </div>
      <div v-if="step == 3">
        <div class="title">How to create your first campaign!</div>
        <p class="subTitle">Step 3 (Add freelance instructions)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-3.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="inline-buttons">
          <a @click="step = 2" href="javascript:void(0)" class="btn btn-default"
            >Back</a
          >
          <a href="javascript:void(0)" @click="step = 4" class="btn btn-primary"
            >Next</a
          >
        </div>
      </div>

      <!-- Step 4 modal -->
      <div v-if="step == 4">
        <div class="title">How to create your first campaign!</div>
        <p class="subTitle">Step 4 (Manage your active campaigns)</p>
        <div class="steps__image">
          <img
            src="@/assets/images/brandtour/step-4.png"
            alt="step-1"
            class="img-fluid"
          />
        </div>
        <div class="button-row">
          <a
            href="javascript:void(0)"
            @click="$bvModal.hide('tour')"
            class="btn btn-primary full"
            >Finish</a
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
    };
  },
  mounted() {
    if (this.$store.state.auth.showTour == 1) {
      this.$refs["tour"].show();
      this.$store.commit("UPDATE_TOUR", 0);
    }
  },
};
</script>
